import type { Context, Plugin } from '@segment/analytics-next';
import interactionLogger from '../attribution/new/attribution';

function enrich(context: Context): Context {
    const { event } = context;

    event.context = event.context || {};

    const gclid = interactionLogger.lastInteraction()?.importantParameters?.gclid ?? interactionLogger.lastInteraction()?.parameters?.gclid;

    // https://support.google.com/google-ads/answer/9744275?hl=en
    event.context = {
        ...event.context,
        gclid,
    };

    return context;
}

export const GclidEnricher: Plugin = {
    alias: enrich,
    group: enrich,
    identify: enrich,
    isLoaded: () => true,
    load: () => Promise.resolve(),
    name: 'GclidEnricher',
    page: enrich,
    track: enrich,
    type: 'before',
    version: '1.0.0',
};
