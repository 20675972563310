import type { Context, Plugin } from '@segment/analytics-next';
import interactionLogger from '../attribution/new/attribution';

function enrich(context: Context): Context {
    const { event } = context;

    event.context = event.context || {};

    // https://businesshelp.snapchat.com/s/article/click-id?language=en_GB
    event.context = {
        ...event.context,
        ScCid: interactionLogger.lastInteraction()?.importantParameters?.ScCid,
    };

    return context;
}

export const SnapchatContextEnricher: Plugin = {
    alias: enrich,
    group: enrich,
    identify: enrich,
    isLoaded: () => true,
    load: () => Promise.resolve(),
    name: 'SnapchatEnricher',
    page: enrich,
    track: enrich,
    type: 'before',
    version: '1.0.0',
};
