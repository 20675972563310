import type { Context, Plugin } from '@segment/analytics-next';
import Cookie from 'js-cookie';

function enrichContext(context: Context): Context {
    const { event } = context;

    event.context = event.context || {};

    // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
    event.context = {
        ...event.context,
        fbc: Cookie.get('_fbc'),
        fbp: Cookie.get('_fbp'),
    };

    return context;
}

export const FacebookContextEnricher: Plugin = {
    alias: enrichContext,
    group: enrichContext,
    identify: enrichContext,
    isLoaded: () => true,
    load: () => Promise.resolve(),
    name: 'FacebookContextEnricher',
    page: enrichContext,
    track: enrichContext,
    type: 'before',
    version: '0.1.0',
};
