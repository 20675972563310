/**
 * Returns a Promise that resolves after the specified amount of time
 * Reject isn't implemented as a timeout never really fails
 *
 * @param delay The amount of milliseconds until the returned promise resolves
 */
const wait = (delay: number): Promise<void> => new Promise((resolve) => {
    setTimeout(resolve, delay);
});

export default wait;
