import type { Context, Plugin } from '@segment/analytics-next';
import { window } from '../globals';

function enrichContext(context: Context): Context {
    const webshopContextKey = window?.siteMetadata.contextKey;

    if (webshopContextKey) {
        const { event } = context;
        event.context = event.context || {};
        event.context = {
            ...event.context,
            webshopContextKey,
        };

        context.event = event;
    }

    return context;
}

export const WebshopContextContextEnricher: Plugin = {
    alias: enrichContext,
    group: enrichContext,
    identify: enrichContext,
    isLoaded: () => !!window?.siteMetadata.contextKey,
    load: () => Promise.resolve(),
    name: 'Context Enrichment',
    page: enrichContext,
    track: enrichContext,
    type: 'before',
    version: '0.1.0',
};
