import type { Product as SegmentProduct } from '@yoursurprise/segment-analytics';

export default class Product {
    private readonly element: HTMLElement;

    private readonly properties: SegmentProduct;

    constructor(element: HTMLElement, product: SegmentProduct & Record<string, unknown>) {
        this.element = element;
        this.properties = product;
    }

    public getElement(): HTMLElement {
        return this.element;
    }

    public getProperties(): SegmentProduct {
        return this.properties;
    }
}
