import { Tracker as SegmentTracker } from '@yoursurprise/segment-analytics';
import AnalyticsService from './AnalyticsService';
import { document } from '../../globals';
import urlDataService from '../UrlDataService';
import interactionLogger from '../../attribution/new/attribution';
import ErrorLogger from '../../error-logger/ErrorLogger';

const element = document?.getElementById('js-analytics-service');
const { siteUrl } = element?.dataset as Record<string, string | undefined>;

if (siteUrl) {
    const tracker = new SegmentTracker();
    const analyticsService = new AnalyticsService(
        siteUrl,
        tracker,
        urlDataService,
        ErrorLogger.createFromGlobals(),
        interactionLogger,
    );

    analyticsService.initAnalytics().catch(() => null);
}
