import type { Tracker } from '@yoursurprise/segment-analytics';
import type Product from './Product';
import ProductFactory from './ProductFactory';
import type ProductListEventsModel from './ProductListEventsModel';
import ProductListModel from './ProductListModel';
import { window } from '../../globals';

class ProductListEventsBinder {
    private readonly model: ProductListEventsModel;

    private readonly tracker: Tracker;

    constructor(productListEventsModel: ProductListEventsModel, tracker: Tracker) {
        this.model = productListEventsModel;
        this.tracker = tracker;
    }

    public processProducts(): void {
        if (!this.model.productsList) {
            return;
        }

        if (this.model.pageAlias) {
            const listName = `${String(this.model.pageAlias)}`;
            const productElements: NodeListOf<HTMLElement> = this.model.productsList.querySelectorAll('.js-productListProduct');
            const products = ProductFactory.newProductsFromElements(productElements, listName);

            const list = ProductListEventsBinder.createList(listName, products);
            if (list.products.length > 0) {
                this.trackProductListViewed(list);
            }

            products.forEach((product) => {
                product.getElement().addEventListener('click', () => this.trackProductClicked(product));
            });
        }
    }

    public processTopProducts(): void {
        if (!this.model.topProductsList) {
            return;
        }

        const listName = this.model.topProductsList.dataset.topProductListGroup;
        if (listName) {
            const productElements: NodeListOf<HTMLElement> = this.model.topProductsList.querySelectorAll('.js-productListProduct');
            const products = ProductFactory.newProductsFromElements(productElements, listName);

            const list = ProductListEventsBinder.createList(listName, products);
            if (list.products.length > 0) {
                this.trackProductListViewed(list);
            }

            products.forEach((product) => {
                product.getElement().addEventListener('click', () => this.trackProductClicked(product));
            });
        }
    }

    private static createList(name: string, products: Product[]): ProductListModel {
        const list = new ProductListModel();
        list.setName(name);
        list.addProducts(products);

        return list;
    }

    /**
     * @see @see https://segment.com/docs/spec/ecommerce/v2/#product-list-viewed
     */
    public trackProductListViewed(productList: ProductListModel): void {
        this.tracker.trackProductListViewed(productList.name, productList.products, { pageId: window?.pageId, pageType: window?.pageType });
    }

    /**
     * @see https://segment.com/docs/spec/ecommerce/v2/#product-clicked
     */
    public trackProductClicked(product: Product): void {
        this.tracker.trackProductClicked(product.getProperties());
    }
}

export default ProductListEventsBinder;
