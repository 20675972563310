import Product from './Product';

export default class ProductFactory {
    private static newProductFromElement(element: HTMLElement, position: number, listName: string): Product {
        const data = element.dataset;

        return new Product(element, {
            currency: data.productCurrency as string,
            imageUrl: data.productImageUrl,
            list: listName,
            name: data.productName as string,
            position,
            price: Number(data.productPrice as string),
            // This property uses snake_case
            // because the Segment GA integration doesn't support camelCase here
            product_id: data.productId,
            productId: data.productId as unknown as number,
            url: data.productUrl,
        });
    }

    public static newProductsFromElements(elements: NodeListOf<HTMLElement>, listName: string): Product[] {
        const products: Product[] = [];

        let position = 0;
        elements.forEach((element) => {
            position += 1;
            products.push(ProductFactory.newProductFromElement(element, position, listName));
        });

        return products;
    }
}
