import { document, window } from '../../globals';

class ProductListEventsModel {
    public readonly topProductsList: HTMLElement | null;
    public readonly productsList: HTMLElement | null;
    public readonly pageAlias: string | undefined;

    constructor() {
        this.topProductsList = document?.querySelector('.js-topProductList') ?? null;
        this.productsList = document?.querySelector('.js-productList') ?? null;
        this.pageAlias = window?.pageName;
    }
}

export default ProductListEventsModel;
