import { window } from '../globals';
import { localStorage } from '../util/storage';

class Ga4Context {
    public static async setGa4Data(): Promise<void> {
        if ((window?.ga4StreamMeasurementId ?? '') !== '') {
            const sessionIdPromise = new Promise((resolve) => {
                gtag('get', window?.ga4StreamMeasurementId ?? '', 'session_id', resolve);
            });

            const sessionNumberPromise = new Promise((resolve) => {
                gtag('get', window?.ga4StreamMeasurementId ?? '', 'session_number', resolve);
            });

            const clientIdPromise = new Promise((resolve) => {
                gtag('get', window?.ga4StreamMeasurementId ?? '', 'client_id', resolve);
            });

            await Promise.all([sessionIdPromise, sessionNumberPromise, clientIdPromise]).then((sessionData) => {
                localStorage.setItem('ga4SessionId', String(sessionData[0]));
                localStorage.setItem('ga4SessionNumber', String(sessionData[1]));
                localStorage.setItem('ga4ClientId', String(sessionData[2]));
            });
        }
    }

    public static getGa4Data(): { ga4ClientId: string|null; ga4SessionId: string|null; ga4SessionNumber: string|null } {
        return {
            ga4ClientId: localStorage.getItem('ga4ClientId'),
            ga4SessionId: localStorage.getItem('ga4SessionId'),
            ga4SessionNumber: localStorage.getItem('ga4SessionNumber'),
        };
    }
}

export default Ga4Context;
