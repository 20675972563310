import type { Tracker } from '@yoursurprise/segment-analytics';
import ProductListEventsBinder from './ProductListEventsBinder';
import ProductListEventsModel from './ProductListEventsModel';

class ProductListEventsBinderFactory {
    public static createProductEventsBinder(tracker: Tracker): ProductListEventsBinder {
        const productListEventsModel = new ProductListEventsModel();

        return new ProductListEventsBinder(productListEventsModel, tracker);
    }
}

export default ProductListEventsBinderFactory;
