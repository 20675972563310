import type { Product as SegmentProduct } from '@yoursurprise/segment-analytics';
import type Product from './Product';

class ProductListModel {
    public name = '';

    public products: SegmentProduct[] = [];

    public addProduct(product: Product): void {
        this.products.push(product.getProperties());
    }

    public addProducts(products: Product[]): void {
        products.forEach((product) => this.addProduct(product));
    }

    public setName(name: string): void {
        this.name = name;
    }
}

export default ProductListModel;
