import { createRoot } from 'react-dom/client';
import { lazy, Suspense } from 'react';
import { document, window } from '../../js/globals';

export interface CookieDialogSettings {
    onDenyConsent: (blocked: boolean) => void;
    onGrantConsent: () => void;
}

const openCookieDialog = ({ onDenyConsent, onGrantConsent }: CookieDialogSettings) => {
    if (document && window) {
        const root = createRoot(document.getElementById('koekjes-muur') as Element);

        const LazyCookieDialog = lazy(() => import('./Components/CookieDialog/CookieDialog'));

        const { privacyPolicyLink, translations } = window.COOKIE_DIALOG_STATE;

        root.render(
            <Suspense>
                <LazyCookieDialog
                    onDenyConsent={onDenyConsent}
                    onGrantConsent={onGrantConsent}
                    privacyPolicyLink={privacyPolicyLink}
                    translations={translations}
                />
            </Suspense>,
        );
    }
};

export default openCookieDialog;
