import type { Context, Plugin } from '@segment/analytics-next';
import Cookie from 'js-cookie';
import { window } from '../globals';

function enrichContext(context: Context): Context {
    const optimizelyUuid = Cookie.get('ysp_optimizely_uuid');

    if (optimizelyUuid) {
        const { event } = context;
        event.context = event.context || {};
        event.context = {
            ...event.context,
            optimizely_site: window?.siteMetadata.siteUrl,
            optimizely_uuid: optimizelyUuid,
        };

        context.event = event;
    }

    return context;
}

export const OptimizelyContextEnricher: Plugin = {
    alias: enrichContext,
    group: enrichContext,
    identify: enrichContext,
    isLoaded: () => true,
    load: () => Promise.resolve(),
    name: 'OptimizelyContextEnricher',
    page: enrichContext,
    track: enrichContext,
    type: 'before',
    version: '0.1.0',
};
