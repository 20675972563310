import type { Context, Plugin } from '@segment/analytics-next';
import Ga4Context from './Ga4Context';

function enrichContext(context: Context): Context {
    const ga4Data = Ga4Context.getGa4Data();

    if (ga4Data.ga4SessionId && ga4Data.ga4SessionNumber) {
        const { event } = context;
        event.context = event.context || {};
        event.context = {
            ...event.context,
            ga4_client_id: ga4Data.ga4ClientId,
            ga4_session_id: ga4Data.ga4SessionId,
            ga4_session_number: ga4Data.ga4SessionNumber,
        };

        context.event = event;
    }

    return context;
}

export const Ga4ContextEnricher: Plugin = {
    alias: enrichContext,
    group: enrichContext,
    identify: enrichContext,
    isLoaded: () => true,
    load: () => Promise.resolve(),
    name: 'Context Enrichment',
    page: enrichContext,
    track: enrichContext,
    type: 'before',
    version: '0.1.0',
};
